/* eslint-disable no-return-await */
import { observer } from 'mobx-react';
import { useContext, useState } from 'react';
import styled from 'styled-components';

import {
  __,
  CaseActions,
  CaseReportDTO,
  CaseStatusList,
  config,
  damageAndAuthorizationConsentItems,
  formatDate,
  getFullName,
  ProductTypes,
  serviceAndPrivacyConsentItems,
} from '@adac/core-model';
import {
  AcceptConditionsContainer,
  ApiDataLoaded,
  Button,
  ButtonListStyles,
  Checkbox,
  CheckboxItem,
  createAddressString,
  Divider,
  ErrorMessage,
  extractAddressLocationNameObject,
  extractCustomerAddressProps,
  extractCustomerPersonProps,
  getOfferText,
  InfoItem,
  OfferPrice,
  Page,
  ProductType,
  request,
  SignatureView,
  Spinner,
  SubPage,
  SubTitle,
  Text,
  TextField,
  Title,
  useAsyncAction,
  useAsyncComponent,
  useLocation,
  useReportRequest,
  UserInfo,
} from '@adac/core-view';

import StoreContext from '../../stores';
import PhotoList from '../composite/PhotoList';

import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';
import { useWorkflowDriverAction } from '../../hooks/useWorkflowDriverAction';
import { RejectCaseOnComissionOpening } from '../overlays/RejectCase';
import {
  BillingAddressDataValues,
  BillingAddressForm,
  emptyDefaultAddress,
} from './BillingAddressForm';

const DividerLine = styled(Divider)`
  margin: 24px auto;
`;

const ComissionConfirm = observer(() => {
  const {
    ui: uiStore,
    case: caseStore,
    beforeOpeningDoorPhotos: doorStore,
  } = useContext(StoreContext);
  const { goBack, goTo } = useLocation();
  const [signatureData, setSignatureData] = useState('');
  const [differentBillingAddress, setDifferentBillingAddress] = useState<
    BillingAddressDataValues | undefined
  >();
  const [showAddress, setShowAddress] = useState(false);
  const [notAccepted, setNotAccepted] = useState(false);
  const [reason, setReason] = useState('');

  const { requestUrl, isView } = useReportRequest(
    caseStore.token,
    caseStore.currentCompanyId
  );

  const { hasLoaded, resource, isLoading } = useAsyncAction(
    async () => await request<CaseReportDTO>(requestUrl),
    [requestUrl]
  );

  const {
    comissionOpening,
    billingAddress: billingAddressFromServer,
    baseAmount,
    baseAmountType,
    companySelectedDate,
  } = resource || {};

  const finalDifferentBillingAddress: BillingAddressDataValues =
    differentBillingAddress ||
    (billingAddressFromServer &&
      extractAddressLocationNameObject(billingAddressFromServer)) ||
    emptyDefaultAddress;

  const isInsurance = useIsInsuranceProduct();

  const showBillingAddressForm = !isView && showAddress;
  const showBillingAddressText =
    !showAddress && !!finalDifferentBillingAddress && !isInsurance;

  const date = new Date();

  const commissionOpeningAction = useWorkflowDriverAction(
    CaseActions.COMISSION_OPENING
  );
  const rejectOpeningAction = useWorkflowDriverAction(
    CaseActions.REJECT_OPENING
  );

  function createPostData() {
    if (notAccepted) {
      return {
        reason,
      };
    }

    return {
      photos: doorStore.photosForPost,
      signature: signatureData,
      consentResults: caseStore.consentResults,
      differentBillingAddress: differentBillingAddress
        ? {
            ...extractCustomerAddressProps(differentBillingAddress),
            ...extractCustomerPersonProps(differentBillingAddress),
          }
        : undefined,
    };
  }

  const onSave = async () => {
    const postData = createPostData();
    await commissionOpeningAction(postData);
    if (!caseStore.hasError) {
      goTo(`/${caseStore.currentCompanyId}`);
    }
  };

  const user = {
    firstName: caseStore.customerFirstName,
    lastName: caseStore.customerFamilyName,
    title: caseStore.title,
    address: caseStore.customerFullAddressString,
  };

  const onReject = async () => {
    const postData = createPostData();
    const status = await rejectOpeningAction(postData);
    if (status === `${CaseStatusList.CASE_COMPLETED}`) goTo('/offer/rejected');
    uiStore.setOverlay(null);
  };

  const showRejectionOverlay = () =>
    uiStore.setOverlay(
      <RejectCaseOnComissionOpening
        onReject={onReject}
        onCancel={() => uiStore.setOverlay(null)}
      />
    );

  const { Component: SaveComissionButton } = useAsyncComponent({
    onClick: onSave,
    disabled:
      !signatureData ||
      doorStore.photosUploadedByUser.length === 0 ||
      !caseStore.openingConsentsAccepted,
  });

  const isReasonInvalid = !reason || reason.trim().length < 10;
  const { Component: RejectCaseButton } = useAsyncComponent({
    onClick: async () => showRejectionOverlay,
    enableAfterLoading: true,
    disabled: isReasonInvalid,
  });

  const showOffer = caseStore.productType === ProductTypes.STANDARD;

  if (isLoading) {
    return (
      <Page>
        <SubPage>
          <Spinner />
        </SubPage>
      </Page>
    );
  }

  if (!resource) return null;

  const offerText = caseStore.scheduledTs
    ? `${__('Agreed appointment time')} ${config.date(caseStore.scheduledTs, 'dddd, \nDD.MM.YYYY, HH:mm')} Uhr `
    : getOfferText(companySelectedDate);

  return (
    <Page>
      <SubPage>
        <ApiDataLoaded hasLoaded={hasLoaded} />
        <Title margin>Beauftragung zur Türöffnung</Title>

        {showOffer && (
          <>
            <OfferPrice amount={baseAmount} type={baseAmountType} />
            <Text margin light>
              {offerText}
            </Text>
          </>
        )}

        <UserInfo {...user} />

        <ProductType
          productType={caseStore.productType}
          productNumber={caseStore.productNumber}
          damageNumber={caseStore.damageNumber}
        />

        {!isView && !isInsurance && (
          <Checkbox
            id='address'
            labelTitle={__('Enter different billing address')}
            checked={showBillingAddressForm}
            value='address'
            onChange={() => setShowAddress(!showAddress)}
            render={CheckboxItem}
            disabled={isView}
          />
        )}
        {showBillingAddressForm && (
          <BillingAddressForm
            defaultValues={finalDifferentBillingAddress}
            onSubmit={async (values: BillingAddressDataValues) => {
              setDifferentBillingAddress(values);
              setShowAddress(false);
            }}
          >
            <Button title={__('Save Changes')} info />
          </BillingAddressForm>
        )}
        {showBillingAddressText && (
          <InfoItem
            header={__('Billing name')}
            info={getFullName(finalDifferentBillingAddress)}
          />
        )}
        {showBillingAddressText && (
          <InfoItem
            header={__('Billing address')}
            info={createAddressString(finalDifferentBillingAddress)}
          />
        )}

        {date && <InfoItem header='Datum' info={formatDate(date)} />}

        <DividerLine fullWidth />

        {!isInsurance && (
          <>
            <SubTitle>
              {__(
                `${`${caseStore.productType === ProductTypes.PREMIUM ? 'Datenschutz' : 'TAC'}`}`
              )}
            </SubTitle>

            <AcceptConditionsContainer
              caseStore={caseStore}
              readOnly={isView}
              consents={serviceAndPrivacyConsentItems}
            />

            <DividerLine fullWidth />
          </>
        )}

        <SubTitle margin>Fotodokumentation der Vorschadenerfassung</SubTitle>
        {(comissionOpening?.photos?.length === 0 ||
          doorStore?.photosUploadedByUser?.length === 0) && (
          <Text>{__('No damage')}</Text>
        )}
      </SubPage>

      <PhotoList
        doorStore={doorStore}
        reportPhotos={
          isView ? comissionOpening && comissionOpening.photos : undefined
        }
      >
        {__('description of damage before door opening')}
      </PhotoList>

      <SubPage>
        <DividerLine fullWidth />

        <AcceptConditionsContainer
          caseStore={caseStore}
          readOnly={isView}
          consents={damageAndAuthorizationConsentItems}
        />

        <SubTitle margin>{__('Signature')}</SubTitle>
      </SubPage>

      {!notAccepted && (
        <SignatureView
          signatureData={isView && comissionOpening?.signature}
          uiStore={uiStore}
          onSignatureChange={setSignatureData}
        />
      )}

      <SubPage>
        <Checkbox
          id='notAccepted'
          labelTitle={__('Kunde verweigert Unterschrift')}
          checked={(isView && comissionOpening?.notAccepted) || notAccepted}
          value='notAccepted'
          onChange={() => {
            setNotAccepted(!notAccepted);
            setSignatureData('');
          }}
          render={CheckboxItem}
          disabled={isView}
        />

        {notAccepted && (
          <>
            <TextField
              name={__('reason')}
              badgeTitle={__('Grund')}
              rows={5}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              badgeEqualsPlaceholder
            />
            {isReasonInvalid && (
              <ErrorMessage>
                {__(
                  'Please provide a cancellation reason with minimum 10 character'
                )}
              </ErrorMessage>
            )}
          </>
        )}
        {isView && comissionOpening?.reason && (
          <Text>{comissionOpening?.reason}</Text>
        )}
      </SubPage>

      {!isView && (
        <SubPage>
          <ButtonListStyles>
            <Button info back title={__('Back')} onClick={() => goBack()} />

            {notAccepted ? (
              <RejectCaseButton cta title={__('Close case')} />
            ) : (
              <SaveComissionButton cta title={__('Confirm')} />
            )}
          </ButtonListStyles>
        </SubPage>
      )}
    </Page>
  );
});

export default ComissionConfirm;
