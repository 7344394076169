import { __ } from '@adac/core-model';
import { Text, Timer, WarningTimeRenderer } from '@adac/core-view';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import StoreContext from '../../stores';
import { LargeText } from '../pages/IncomingDriverJob';

const scheduledCountdownStartThreshold =
  Number(
    process.env.REACT_APP_SCHEDULED_COUNTDOWN_REMINDER_THRESHOLD_MINUTES
  ) || 120;

const ScheduledText = styled.p`
  font-size: ${(props) => props.theme.font.subTitle};
  margin-top: 8px;
  font-weight: bold;
`;

const ScheduledTextTitle = styled.span`
  color: ${(p) => p.theme.colors.warning};
`;

export function AppointmentTime() {
  const {
    case: { scheduledTs },
  } = useContext(StoreContext);

  return (
    <ScheduledText>
      <ScheduledTextTitle>{__('Appointment')}: </ScheduledTextTitle>
      <span>{`${moment(scheduledTs).format('DD/MM/YYYY, HH:mm')} ${__('hour')}`}</span>
    </ScheduledText>
  );
}

export function AppointmentCountDown() {
  const {
    case: { scheduledTs },
  } = useContext(StoreContext);

  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const timeDiffInMinutes = moment(scheduledTs).diff(currentTime, 'minutes');
  const secondDiffInMinutes = moment(scheduledTs).diff(currentTime, 'seconds');

  if (scheduledTs && timeDiffInMinutes <= 0 && secondDiffInMinutes <= 0) {
    return (
      <ScheduledText>
        <ScheduledTextTitle>
          {__('Attention, scheduled task')}:
        </ScheduledTextTitle>
        <Timer
          startTime={new Date(scheduledTs)}
          suffix={__('fällig!')}
          renderer={WarningTimeRenderer}
        />
      </ScheduledText>
    );
  }

  if (scheduledTs && timeDiffInMinutes < scheduledCountdownStartThreshold) {
    return (
      <ScheduledText>
        <LargeText bold>{__('Scheduled task starts in')}:</LargeText>
        <Timer
          startTime={new Date(scheduledTs)}
          countdown
          onCountdown={<Text bold>00:00</Text>}
          suffix=''
        />
      </ScheduledText>
    );
  }

  return <AppointmentTime />;
}
