import styled from 'styled-components';

import { __ } from '@adac/core-model';
import {
  Button,
  FooterDescription,
  FooterTitle,
  Icon,
  PhotoFooter,
  PhotoHeader,
  PhotoHeaderIconStyles,
  PhotoTitle,
} from '@adac/core-view';

const Styles = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 56px;
  bottom: 0;

  img {
    display: block;
    margin-left: 50%;
    transform: translateX(-50%);
    min-height: 100%;
    min-width: 100%;
  }
`;

interface Props {
  index: number;
  photoUrl: string;
  description?: string;
  onCloseClicked?: () => void;
}

export const FullScreenPhoto = ({
  index,
  photoUrl,
  description,
  onCloseClicked,
}: Props) => (
  <Styles>
    <PhotoHeader>
      <PhotoTitle>{`#${index}`}</PhotoTitle>
      <PhotoHeaderIconStyles>
        <Button onClick={onCloseClicked}>
          <Icon name='close' color='white' />
        </Button>
      </PhotoHeaderIconStyles>
    </PhotoHeader>
    <img src={photoUrl} alt='door' />
    {description && (
      <PhotoFooter>
        <FooterTitle>
          {__('description of damage before door opening')}
          {index}
        </FooterTitle>
        <FooterDescription>{description}</FooterDescription>
      </PhotoFooter>
    )}
  </Styles>
);
