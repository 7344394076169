import { __, BasePriceTypes, config } from '@adac/core-model';
import { getOfferText, OfferPriceView, Text } from '@adac/core-view';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';
import StoreContext from '../../stores';

interface PriceDetailsProps {
  baseAmountType?: BasePriceTypes;
  companySelectedDate?: Date;
  children?: React.ReactNode;
}
export const PriceDetails = observer(
  ({ baseAmountType, companySelectedDate, children }: PriceDetailsProps) => {
    const isInsurance = useIsInsuranceProduct();
    const { case: caseStore } = useContext(StoreContext);

    const offerText = caseStore.scheduledTs
      ? `${__('Agreed appointment time')} ${config.date(caseStore.scheduledTs, 'dddd, \nDD.MM.YYYY, HH:mm')} Uhr `
      : getOfferText(
          companySelectedDate,
          config.date(companySelectedDate, 'dddd, ')
        );

    return (
      <>
        {!isInsurance && <OfferPriceView type={baseAmountType} />}
        <Text margin light>
          {offerText}
        </Text>
        {children}
      </>
    );
  }
);
