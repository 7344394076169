import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { isStringData, reportSelectors } from '@adac/core-model';
import {
  base64ToFile,
  DoorPhoto,
  DoorPhotoStore,
  PhotoFromBackend,
  PhotoUploadedByUser,
  SubPage,
  Text,
  View,
} from '@adac/core-view';

interface PhotoListProps {
  doorStore: DoorPhotoStore;
  reportPhotos?: PhotoFromBackend[] | null;
  children?: React.ReactNode;
}

interface PhotosLoadedType {
  [key: string]: boolean;
}

const PhotoList = observer(
  ({ doorStore, reportPhotos = [], children }: PhotoListProps) => {
    const [photos, setPhotos] = useState(doorStore.photosUploadedByUser);
    const [photosLoaded, setPhotosLoaded] = useState({} as PhotosLoadedType);

    const length = reportPhotos?.length;

    useEffect(() => {
      if (!reportPhotos) return;
      if (reportPhotos.length > 0) {
        const mappedPhotos: Promise<PhotoUploadedByUser>[] = reportPhotos.map(
          async (photo) => {
            if (!isStringData(photo.image)) {
              return {
                url: photo.image,
                file: undefined,
                description: photo.text,
              };
            }
            const { file, url } = await base64ToFile(photo.image);
            return {
              description: photo.text,
              file,
              url,
            };
          }
        );
        if (mappedPhotos.length > 0) {
          Promise.all(mappedPhotos).then(setPhotos);
        }
      }
    }, [reportPhotos, length]);

    const newPhotoLoaded = (url: string) =>
      setPhotosLoaded({
        ...photosLoaded,
        [url]: true,
      });

    const isAllLoaded =
      photos.length > 0 &&
      photos.filter((photo) => photosLoaded[photo.url]).length ===
        photos.length;

    if (!reportPhotos) return null;

    return (
      <View
        className={(isAllLoaded && reportSelectors.DOOR_PHOTO_SELECTOR) || ''}
      >
        {photos.map((ph, idx) => (
          <View key={ph.url}>
            <SubPage padding='24px' background='#f3f3f4'>
              <DoorPhoto
                src={ph.url}
                index={idx + 1}
                onLoaded={() => newPhotoLoaded(ph.url)}
              />
            </SubPage>
            <SubPage>
              <Text bold>
                {children}
                {idx + 1}
              </Text>
              <Text>{ph.description}</Text>
            </SubPage>
          </View>
        ))}
      </View>
    );
  }
);

export default PhotoList;
